import request from '@/utils/request'

// 双生世界初始化页
export const queryTwinWworldPage = (params) => {
  return request('GET', '/app/twin_world/twin_world_page', params)
}

// 好友列表
export const queryMyFriendsList = (params, loadingType) => {
  return request('GET', '/app/twin_world/my_friends', params, true, false, loadingType)
}
// 获取邀请码
export const queryGetInviteCode = (params) => {
  return request('GET', '/app/wallet/get_invite_code', params)
}

// 获取上级 邀请人
export const queryGetInvitee = (params) => {
  return request('GET', '/app/twin_world/my_superior', params)
}
