<template>
  <div class="invite-wrap">
    <div class="invite-code-list">
      <!-- <div class="friend-btn" @click="$gbUtils.handleToPage('/myFriends')">
         <svg class="friend-icon" aria-hidden="true">
          <use xlink:href="#icon-friend"></use>
        </svg>
        {{ myFriend }}
      </div> -->
      <div
        class="invite-code-item"
        data-clipboard-action="copy"
        :data-clipboard-text="inviteCode.betaInviteCode"
        @click="handleCopyBlphaInviteCode()"
      >
        <div>{{ inviteCode.betaInviteCode || '-' }}</div>
        <i class="iconfont icon-fuzhi"></i>
      </div>
    </div>
    <div
      class="invite-link"
      data-clipboard-action="copy"
      :data-clipboard-text="appLink"
      @click="handleCopyAppLink()"
    >
      <div class="title">{{ inviteInfo.appLink }}</div>
      <div class="link-body">{{ appLink }}</div>
      <i class="iconfont icon-fuzhi"></i>
    </div>
    <div class="invite-img-box" v-if="false">
      <div class="qr-code-wrap">
        <img class="qr-img" src="../../static/img/twins/invite-bg.png" />
        <vueQr
          colorDark="#000"
          :text="`https://app.meblox.io/?mbCode=${inviteCode.betaInviteCode}`"
          :size="65"
          :margin="5"
          class="qr-code"
        ></vueQr>
      </div>
    </div>
  </div>
</template>

<script>
import { queryGetInviteCode } from '@/services/twins'
import vueQr from 'vue-qr'
import Clipboard from 'clipboard'
export default {
  components: { vueQr },
  data () {
    return {
      inviteCode: {},
      upDateLink: '暂无链接'
    }
  },
  methods: {
    handleSavePoster () {
    },
    async getInviteCode () {
      const resp = await queryGetInviteCode()
      if (!resp.success) return
      console.log(resp)
      this.inviteCode = resp.result
      this.inviteCode.alphaInviteCode = this.inviteCode.alphaInviteCode.toUpperCase()
      this.inviteCode.betaInviteCode = this.inviteCode.betaInviteCode.toUpperCase()
    },
    handleCopyAlphaInviteCode () {
      const clipboard = new Clipboard('.invite-code-item')
      clipboard.on('success', () => {
        return this.$toast(this.inviteInfo.copy)
      })
    },
    handleCopyBlphaInviteCode () {
      const clipboard = new Clipboard('.invite-code-item')
      clipboard.on('success', () => {
        return this.$toast(this.inviteInfo.copy)
      })
    },
    handleCopyAppLink () {
      const clipboard = new Clipboard('.invite-link')
      clipboard.on('success', () => {
        return this.$toast(this.inviteInfo.copy)
      })
    }

  },
  computed: {
    savePosters () {
      return this.$t('myFriends.savePosters')
    },
    inviteInfo () {
      return this.$t('twins')
    },
    myFriend () {
      return this.$t('twins.myFriend')
    },
    appLink () {
      return `https://app.meblox.io/#/?mbCode=${this.inviteCode.betaInviteCode}`
    }
  },
  mounted () {
    this.getInviteCode()
  }
}
</script>

<style lang="scss">
.invite-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  overflow: hidden;
  .invite-img-box {
    // background: url("../../static/img/twins/invite-bg.png");
    // background-size: 100% 100%;
    // width: 70%;
    // height: 700px;
    // width: 280px;
    // height: 498px;
    // max-width: 280px;
    box-sizing: border-box;
    margin: auto;
    text-align: center;
    .qr-code-wrap {
      width: 100%;
      position: relative;
      // max-width: 280px;
      .qr-img {
        width: 100%;
        height: auto;
      }
      text-align: right;
      // margin-top: 380px;
      .tips {
        font-size: 13px;
        font-weight: 700;
        color: #5f493d;
      }
      .qr-code {
        position: absolute;
        right: 25px;
        bottom: 25px;
        border-radius: 3px;
      }
    }
  }
  .invite-code-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 12px;
    width: 100%;
    border-radius: 10px;
    margin: 0 auto 10px auto;
    background-color: #fff;
    padding: 10px 20px;
    box-sizing: border-box;
    .invite-code-item {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      // justify-content: flex-start;
      align-items: center;
      background: #24c294;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .iconfont {
        font-size: 14px;
        color: #fff;
        margin-left: 8px;
      }
    }
    .friend-btn {
      width: 48%;
      height: 50px;
      height: 40px;
      background: #16172a;
      font-weight: bold;
      opacity: 1;
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      cursor: pointer;
      .friend-icon {
        width: 15px;
        height: 20px;
        display: inline;
        margin-right: 8px;
      }
    }
  }
}
.icon-fuzhi {
  color: #24c294;
}
.invite-link {
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
  .title {
    color: #818086;
    margin-right: 10px;
    width: 60px;
  }
  .link-body {
    width: calc(100% - 100px);
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
    overflow: hidden;
  }
  .icon-fuzhi {
    margin-left: 15px;
    width: 20px;
  }
}
</style>
